const handleIntersect = (entries: IntersectionObserverEntry[]): void => {
  const visibilityMap = new Map();

  entries.forEach((entry) => {
    const bgTarget = entry.target.getAttribute("data-target");
    if (!bgTarget) return;

    visibilityMap.set(bgTarget, entry.isIntersecting);
  });

  // Batch DOM operations
  requestAnimationFrame(() => {
    visibilityMap.forEach((isVisible, target) => {
      const element = document.querySelector(`.animation-${target}`);
      if (element) {
        element.classList.toggle("opacity-0", !isVisible);
        element.classList.toggle("opacity-100", isVisible);
      }
    });
  });
};

export const observeElementIntersection = (identifier: string) => {
  if (!document) return;
  const options: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  const observer = new IntersectionObserver(handleIntersect, options);
  const targets = document.querySelectorAll(identifier);

  targets.forEach((target) => observer.observe(target));
};
