export const RESOURCE_BACK_TO_HUB_BUTTON = {
  "en": "Back to hub",
  "fr": "Back to hub",
  "de": "Back to hub",
  "es": "Back to hub",
  "pt-br": "Back to hub",
  "nl": "Back to hub",
  "it": "Back to hub",
  "ja": "Back to hub",
  "ko": "Back to hub",
  "pt-pt": "Back to hub",
  "sv": "Back to hub",
  "zh": "Back to hub"
}