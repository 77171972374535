export const RESOURCE_ARCHIVE_VIEW_BUTTON = {
  "en": "Learn more",
  "fr": "Learn more",
  "de": "Learn more",
  "es": "Learn more",
  "pt-br": "Learn more",
  "nl": "Learn more",
  "it": "Learn more",
  "ja": "Learn more",
  "ko": "Learn more",
  "pt-pt": "Learn more",
  "sv": "Learn more",
  "zh": "Learn more"
}