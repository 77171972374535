export const RESOURCE_SEARCH_CHOOSE_CATEGORY = {
  "en": "Choose a category...",
  "fr": "Choose a category...",
  "de": "Choose a category...",
  "es": "Choose a category...",
  "pt-br": "Choose a category...",
  "nl": "Choose a category...",
  "it": "Choose a category...",
  "ja": "Choose a category...",
  "ko": "Choose a category...",
  "pt-pt": "Choose a category...",
  "sv": "Choose a category...",
  "zh": "Choose a category..."
}