export const BLOG_MIN_READ = {
  "en": "min read",
  "fr": "min de lecture",
  "de": "Min. Lesezeit",
  "es": "min de lectura",
  "pt-br": "min read",
  "it": "min read",
  "ja": "min read",
  "ko": "min read",
  "nl": "min read",
  "pt-pt": "min read",
  "sv": "min read",
  "zh": "min read"
}