export const PRIVACY_CONSENT_TITLE_CTA_REDESIGN = {
  "en": "Manage cookie preferences",
  "fr": "",
  "de": "",
  "es": "",
  "pt-br": "Manage cookie preferences",
  "it": "Manage cookie preferences",
  "ja": "Manage cookie preferences",
  "ko": "Manage cookie preferences",
  "nl": "Manage cookie preferences",
  "pt-pt": "Manage cookie preferences",
  "sv": "Manage cookie preferences",
  "zh": "Manage cookie preferences"
}