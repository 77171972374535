export const sectionPadding = "p-20"; /* 80px */
export const sectionPaddingY = "py-14 md:py-20"; /* 56px 80px */
export const sectionPaddingSm = "p-10"; /* 40px */
export const sectionPaddingYSm = "py-10"; /* 40px */
export const innerSectionPadding = "px-10 py-8"; /* 40px 32px */
export const boxPadding = "p-4 md:p-14"; /* 56px */
export const boxPaddingY = "py-14 md:py-20"; /* 56px 80px */
export const boxPaddingX = "px-4 md:px-14"; /* 56px */

export const narrowWidth = "max-w-xl";
export const innerContainer = "mx-auto w-full max-w-7xl px-4 md:px-8";

export const marginBXL = "mb-8 md:mb-12"; /* 48px */
export const marginBLg = "mb-8 md:mb-10"; /* 40px */
export const marginBMd = "mb-4 md:mb-6"; /* 24px */
export const marginBSm = "mb-2 md:mb-4"; /* 16px */
export const marginBXS = "mb-2"; /* 8px */
export const marginB2XS = "mb-1"; /* 4px */
export const marginTXL = "mt-8 md:mt-12"; /* 48px */
export const marginTLg = "mt-8 md:mt-10"; /* 40px */
export const marginTMd = "mt-4 md:mt-6"; /* 24px */
export const marginTSm = "mt-4"; /* 16px */
export const marginTXS = "mt-2"; /* 8px */
export const marginT2XS = "mt-1"; /* 4px */

export const headingCard = "font-medium text-2xl";
export const headingCardSm = "font-medium text-lg";
export const h1TextLarge = "text-5xl md:text-7xl font-medium"; /* 60px 72px */
export const h1Text = "text-5xl md:text-6xl font-medium"; /* 48px 60px */
export const h2Text = "text-4xl md:text-5xl font-medium"; /* 36px 48px */
export const h3Text = "text-3xl md:text-4xl font-medium"; /* 30px 36px */
export const h4Text = "text-2xl md:text-3xl font-medium"; /* 24px 30px */
export const h5Text = "text-xl md:text-2xl font-medium"; /* 20px 24px */
export const h6Text = "text-xl font-medium"; /* 20px */
export const pText2XL = "text-2xl"; /* 24px */
export const pTextXL = "text-xl"; /* 20px */
export const pTextL = "text-lg"; /* 18px */
export const pTextSm = "text-sm"; /* 14px */

export const eyebrowText = "mb-2 md:mb-4 text-base";
export const belowButtonText = "mt-4 text-sm";

export const textHeadlineNeutral = "text-blue-8";
export const textBodyNeutral = "text-grey-6";
export const textBodyCatchyNeutral = "text-blue-5";
export const textHeadlineInverse = "text-white";
export const textBodyInverse = "text-grey-3";
export const textBodyCatchyInverse = "text-blue-3";
export const textBodyCatchyInverseAlt = "text-inverse-catchy";

export const imageFillNeutral = "dark-green-image";
export const imageFillInverse = "white-image";
export const imageFillStandard = "blue-6-image";
export const imageFillDark = "blue-8-image";
export const imageFillCatchyStandard = "blue-5-image";
export const imageFillCatchyInverse = "blue-3-image";

export const iconFillNeutral = "fill-grey-6";
export const iconFillInverse = "fill-white";
export const iconFillStandard = "fill-blue-6";
export const iconFillDark = "fill-blue-8";
export const iconBorderNeutral = "border-grey-6";
export const iconBorderInverse = "border-white";

export const backgroundInverse = "bg-blue-8";
export const backgroundInverseLight = "bg-blue-6";
export const backgroundInverseLightAlt = "bg-blue-7";
export const backgroundNeutral = "bg-blue-1";
export const backgroundNeutralDark = "bg-blue-2";
export const backgroundWhiteDark = "bg-blue-1";
export const backgroundWhite = "bg-white";

export const borderBrand = "border-blue-6";
export const borderRecommended = "border-blue-8";
export const borderInverse = "border-blue-grey";
export const borderNeutral = "border-grey-3";
export const borderBoth = "border-grey-1";

export const buttonBrand =
  "bg-blue-6 hover:bg-blue-7 active:bg-blue-8 disabled:opacity-10 text-white";
export const buttonLight =
  "bg-blue-2 hover:bg-blue-2h active:bg-blue-2a disabled:opacity-30 text-blue-8";
export const buttonWhite =
  "bg-white hover:bg-white-7 active:bg-white-8 disabled:bg-white-disabled text-blue-8";
export const buttonWhiteBorder =
  "bg-white hover:bg-white-7 active:bg-white-8 disabled:bg-white-disabled text-blue-8 border border-solid border-blue-8";
