export const POST_SHARE_TITLE_V2 = {
  "en": "Share content",
  "fr": "Partager le contenu",
  "de": "Inhalte teilen",
  "es": "Compartir contenido",
  "pt-br": "Share content",
  "it": "Share content",
  "ja": "Share content",
  "ko": "Share content",
  "nl": "Share content",
  "pt-pt": "Share content",
  "sv": "Share content",
  "zh": "Share content"
}