export const TABLE_OF_CONTENTS_TITLE = {
  "en": "Table of contents",
  "fr": "Table des matières",
  "de": "Inhaltsverzeichnis",
  "es": "Índice",
  "pt-br": "Table of contents",
  "it": "Table of contents",
  "ja": "Table of contents",
  "ko": "Table of contents",
  "nl": "Table of contents",
  "pt-pt": "Table of contents",
  "sv": "Table of contents",
  "zh": "Table of contents"
}