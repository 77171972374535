export const RESOURCE_SEARCH_HEADING = {
  "en": "Resource library search",
  "fr": "Resource library search",
  "de": "Resource library search",
  "es": "Resource library search",
  "pt-br": "Resource library search",
  "nl": "Resource library search",
  "it": "Resource library search",
  "ja": "Resource library search",
  "ko": "Resource library search",
  "pt-pt": "Resource library search",
  "sv": "Resource library search",
  "zh": "Resource library search"
}