import React from "react";
import { gql } from "@apollo/client";
import * as MENUS from "@/constants/menus";
import { ResourcesCardFragment } from "@/fragments";
import { ArchiveTemplate } from "@/components/organisms";
import type {
  Locale,
  OffsetPagination,
  RelatedResource,
  SeedNode,
} from "@/types";
import { PAGE_SIZE } from "@/constants/utility";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import { GetArchive } from "@/fragments/fragmentFunctions";
import { formatEventDate } from "@/utilities/formatEventDate";
import ArchiveBackButton from "@/components/atoms/ArchiveBackButton";
import { CardInterface } from "@/components/molecules/Card";
import ContentCards from "@/components/molecules/ContentCards";
import cn from "classnames";
import { h3Text, innerContainer } from "@/constants/standardCSSClasses";
import { mapResourcesToCards } from "@/utilities/mapResourcesToCards";
import { BlockAttributesJson } from "@/types";
import { Blocks } from "@/components/molecules";
import { Site } from "@/types";
import {
  RESOURCE_ARCHIVE_ALL_HEADING,
  RESOURCE_ARCHIVE_FEATURED_HEADING,
  RESOURCE_BACK_TO_HUB_BUTTON,
  RESOURCE_ARCHIVE_UPCOMING_HEADING,
  GENERIC_VIEW_MORE,
} from "@/i18n/translations";
import { useTranslate } from "@/hooks/useTranslate";
import { useBrowserLanguage } from "@/hooks/useBrowserLanguage";
import { SearchForm } from "@/components/atoms";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";

interface Props extends Site {
  data: Site["data"] & {
    resourceCategory: {
      name: string;
      description: string;
    };
    webinarsCategory: {
      name: string;
      description: string;
      link: string;
    };
    conferencesCategory: {
      name: string;
      description: string;
      link: string;
    };
    tutorialsCategory: {
      name: string;
      description: string;
      link: string;
    };
    resources: {
      nodes: RelatedResource[];
      pageInfo: {
        offsetPagination: OffsetPagination;
      };
    };
    featured: {
      nodes: RelatedResource[];
    };
    upcoming: {
      nodes: RelatedResource[];
    };
    webinars: {
      nodes: RelatedResource[];
    };
    conferences: {
      nodes: RelatedResource[];
    };
    tutorials: {
      nodes: RelatedResource[];
    };
    wpBlock: {
      editorBlocks: BlockAttributesJson[];
    };
  };
}

export default function Component(props: Props) {
  const { translate, translateInterpolate } = useTranslate();
  const { locale } = props;
  const { language } = useBrowserLanguage(locale);
  const categoryName = props.__SEED_NODE__.slug;
  const uri = props.__SEED_NODE__.uri;
  const nodes = props.data.resources.nodes;
  const featured = props.data.featured.nodes;
  const upcoming = props.data.upcoming.nodes;
  const webinars = props.data.webinars.nodes;
  const conferences = props.data.conferences.nodes;
  const tutorials = props.data.tutorials.nodes;
  const name = props.data.resourceCategory.name;
  const webinarsCategory = props.data.webinarsCategory;
  const conferencesCategory = props.data.conferencesCategory;
  const tutorialsCategory = props.data.tutorialsCategory;

  // @todo: Once the transition has been made to the subcategories, the main query should be removed
  const posts = nodes.map((each) => {
    return {
      ...each,
      date: "",
      eventDate: formatEventDate(each.resourceFields.eventDate, language, true),
      categories: each.resourceTypes ?? { nodes: [] },
      translated: [],
      uri: each.resourceFields.externalUrl
        ? each.resourceFields.externalUrl
        : each.uri,
    };
  });

  const featuredCards: Array<CardInterface> = mapResourcesToCards(
    featured,
    locale,
    "resourceTypes"
  );
  const upcomingCards: Array<CardInterface> = mapResourcesToCards(
    upcoming,
    locale,
    "resourceTypes"
  );
  const webinarCards: Array<CardInterface> = mapResourcesToCards(
    webinars,
    locale,
    "resourceTypes"
  );
  const conferenceCards: Array<CardInterface> = mapResourcesToCards(
    conferences,
    locale,
    "resourceTypes"
  );
  const tutorialCards: Array<CardInterface> = mapResourcesToCards(
    tutorials,
    locale,
    "resourceTypes"
  );

  const newLayout =
    webinarCards.length >= 1 &&
    conferenceCards.length >= 1 &&
    tutorialCards.length >= 1;

  const offsetPagination = props.data.resources.pageInfo.offsetPagination;
  const { page = 1 } = offsetPagination || {};

  return (
    <ArchiveTemplate
      name={name}
      inserts={{
        beforeTitle: (
          <ArchiveBackButton
            text={translate(RESOURCE_BACK_TO_HUB_BUTTON)}
            href={"resources"}
          />
        ),
        afterTitle: (
          <SearchForm
            className="mb-6 max-w-xl"
            path="/search"
            basePathOverwrite="/resources"
            category={categoryName}
            filters={{ resourceType: true, resourceCategory: true }}
          />
        ),
        featured: (
          <>
            {(newLayout || page <= 1) && featuredCards.length ? (
              <ContentCards
                featureSingle={true}
                includeAboveHeading={false}
                includeButtons={false}
                includeContent={false}
                heading={translate(RESOURCE_ARCHIVE_FEATURED_HEADING)}
                headingOptions={{
                  tag: 2,
                }}
                variant="white"
                contentType=""
                loading={false}
                cards={featuredCards}
                columns={featuredCards.length <= 2 ? 2 : 3}
              />
            ) : null}
            {newLayout && tutorialCards.length ? (
              <ContentCards
                featureSingle={false}
                includeAboveHeading={false}
                includeButtons={true}
                button={{
                  text: translate(GENERIC_VIEW_MORE),
                  href: tutorialsCategory.link,
                }}
                buttonBottom={true}
                includeContent={true}
                heading={tutorialsCategory.name}
                headingOptions={{
                  tag: 2,
                }}
                content={tutorialsCategory.description}
                variant="light"
                contentType=""
                loading={false}
                cards={tutorialCards}
                columns={tutorialCards.length <= 2 ? 2 : 3}
              />
            ) : null}
            {newLayout && webinarCards.length ? (
              <ContentCards
                featureSingle={false}
                includeAboveHeading={false}
                includeButtons={true}
                button={{
                  text: translate(GENERIC_VIEW_MORE),
                  href: webinarsCategory.link,
                }}
                buttonBottom={true}
                includeContent={true}
                heading={webinarsCategory.name}
                headingOptions={{
                  tag: 2,
                }}
                content={webinarsCategory.description}
                variant="white"
                contentType=""
                loading={false}
                cards={webinarCards}
                columns={webinarCards.length <= 2 ? 2 : 3}
              />
            ) : null}
            {newLayout && conferenceCards.length ? (
              <ContentCards
                featureSingle={false}
                includeAboveHeading={false}
                includeButtons={true}
                button={{
                  text: translate(GENERIC_VIEW_MORE),
                  href: conferencesCategory.link,
                }}
                buttonBottom={true}
                includeContent={true}
                heading={conferencesCategory.name}
                headingOptions={{
                  tag: 2,
                }}
                content={conferencesCategory.description}
                variant="light"
                contentType=""
                loading={false}
                cards={conferenceCards}
                columns={conferenceCards.length <= 2 ? 2 : 3}
              />
            ) : null}
            {newLayout && upcomingCards.length ? (
              <ContentCards
                featureSingle={true}
                includeAboveHeading={false}
                includeButtons={false}
                includeContent={false}
                heading={translate(RESOURCE_ARCHIVE_UPCOMING_HEADING)}
                headingOptions={{
                  tag: 2,
                }}
                variant="white"
                contentType=""
                loading={false}
                cards={upcomingCards}
                columns={upcomingCards.length <= 2 ? 2 : 3}
              />
            ) : null}
          </>
        ),
        beforeArchive:
          !newLayout && featuredCards.length ? (
            <h2 className={cn(h3Text, "mb-10 mt-20")}>
              {translateInterpolate(RESOURCE_ARCHIVE_ALL_HEADING, {
                category: name,
              })}
            </h2>
          ) : (
            <div className="mb-20" />
          ),
        afterPagination: props.data.wpBlock?.editorBlocks && (
          <div className={innerContainer}>
            <Blocks
              blocks={flatListToHierarchical(props.data.wpBlock?.editorBlocks, {
                idKey: "id",
                parentKey: "parentClientId",
                childrenKey: "innerBlocks",
              })}
            />
          </div>
        ),
      }}
      posts={posts}
      hidePostSection={newLayout}
      locale={locale}
      categoryName={categoryName}
      metaTitle={props.data.generalSettings.title}
      description={props.data.resourceCategory.description}
      metaDescription={props.data.generalSettings.description}
      primaryMenu={props.data.headerMenuItems.nodes ?? []}
      newMenu={props.data.newMenuItems.nodes ?? []}
      offsetPagination={offsetPagination}
      footerMenu={props.data.footerMenuItems.nodes ?? []}
      hero={true}
      uri={uri}
    />
  );
}

Component.query = GetArchive(
  `
    resourceCategory: resourceCategory(id: $categoryName, idType: SLUG) {
      name
      description
    }
    webinarsCategory: resourceCategory(id: "webinars-digital-events", idType: SLUG) {
      name
      description
      link
    }
    conferencesCategory: resourceCategory(id: "conferences-in-person-events", idType: SLUG) {
      name
      description
      link
    }
    tutorialsCategory: resourceCategory(id: "tutorials-demos", idType: SLUG) {
      name
      description
      link
    }
    resources: resources(where: { resourceCategoryName: $categoryName, offsetPagination: { offset: $offset, size: $size } }) {
      ...RootQueryToResourceConnectionFragment
    }
    webinars: resources(first: 6, where: { resourceCategoryName: "webinars-digital-events" }) {
      ...RootQueryToResourceConnectionFragment
    }
    conferences: resources(first: 6, where: { resourceCategoryName: "conferences-in-person-events" }) {
      ...RootQueryToResourceConnectionFragment
    }
    tutorials: resources(first: 6, where: { resourceCategoryName: "tutorials-demos" }) {
      ...RootQueryToResourceConnectionFragment
    }
    upcoming: resources(first: 6, where: { upcoming: 1, resourceCategoryName: $categoryName }) {
      ...RootQueryToResourceConnectionFragment
    }
    featured: resources(first: 6, where: { featured: 1, resourceCategoryName: $categoryName }) {
      ...RootQueryToResourceConnectionFragment
    }`,
  gql`
    ${ResourcesCardFragment}
  `
);

Component.variables = (
  { slug }: SeedNode,
  { locale }: { locale: Locale },
  extra: any
) => {
  const { page } = extra;
  return {
    categoryName: slug,
    headerLocation: MENUS.newHeaderLocation(locale),
    footerLocation: MENUS.newFooterLocation(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    size: PAGE_SIZE,
    offset: page ? PAGE_SIZE * (page - 1) : 0,
    blockId: process.env["NEXT_PUBLIC_RESOURCE_PATTERN_ID"],
  };
};
