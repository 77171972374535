import React, { useEffect } from "react";

import CloseIcon from "../icons/CloseIcon";
import getCookieDomain from "@/utilities/getCookieDomain";
import Cookies from "js-cookie";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { useTranslate } from "@/hooks/useTranslate";
import {
  PRIVACY_CONSENT_ACCEPT,
  PRIVACY_CONSENT_REFUSE,
  PRIVACY_CONSENT_TITLE_CTA,
  PRIVACY_CONSENT_TITLE_NEW,
} from "@/i18n/translations";
import { I18nElement } from "@/i18n/i18nElement";

export interface CookieBannerProps {
  setShowCookieBanner: any;
  setLoadTrackers: any;
  isVisible?: boolean;
  isImplicitConsent?: boolean;
}

export default function CookieBanner({
  isVisible = false,
  setShowCookieBanner,
  setLoadTrackers,
  isImplicitConsent = false,
}: CookieBannerProps) {
  const { translate, locale } = useTranslate();

  const buttons = [
    {
      variant: "dark",
      text: translate(PRIVACY_CONSENT_ACCEPT),
      size: "sm",
      className: "block",
      onClick: () => onAccept(setShowCookieBanner, setLoadTrackers),
    },
    {
      variant: "light",
      text: translate(PRIVACY_CONSENT_REFUSE),
      size: "sm",
      className: "block",
      onClick: () => onRefuse(setShowCookieBanner),
    },
  ];

  useEffect(() => {
    if (isImplicitConsent) {
      onAccept(setShowCookieBanner, setLoadTrackers, isImplicitConsent);
    }
  }, [isImplicitConsent]);

  return (
    <div
      className={`fixed bottom-0 left-0 z-20 w-full bg-blue-light ${
        !isVisible && "hidden"
      }`}
    >
      <div className="banner-wrap ml-auto mr-auto flex max-w-6xl flex-col items-center gap-10 pb-8 pl-10 pr-10 pt-8 md:flex-row md:gap-20">
        <div className="banner-copy" aria-hidden={!isVisible}>
          <p>
            <span>
              <I18nElement
                i18nKey={PRIVACY_CONSENT_TITLE_NEW}
                locale={locale}
              />{" "}
              <a
                href={
                  locale !== "en"
                    ? `https://www.dashlane.com/${locale}/privacy/cookiepreferences`
                    : "https://www.dashlane.com/privacy/cookiepreferences"
                }
                className="underline"
              >
                <I18nElement
                  i18nKey={PRIVACY_CONSENT_TITLE_CTA}
                  locale={locale}
                />
              </a>
            </span>
          </p>
        </div>
        <div className="banner-cta-container flex w-full flex-col justify-between gap-4 md:w-auto md:flex-row">
          <CustomButtonGroup align="right" buttons={buttons} marginTop="" />
          <button
            className="absolute right-5 top-4 cursor-pointer md:bottom-0 md:top-0 md:my-auto"
            onClick={() => setShowCookieBanner(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

function onAccept(
  setShowCookieBanner: any,
  setLoadTrackers: any,
  isImplicitConsent: boolean = false
) {
  Cookies.set(
    "userPrivacyCookie",
    JSON.stringify({
      analytics: { enabled: true, date: +new Date() },
      marketing: { enabled: true, date: +new Date() },
    }),
    {
      expires: 365,
      path: "/",
      secure: true,
      sameSite: "Strict",
      domain: getCookieDomain(window.location.hostname),
    }
  );
  setLoadTrackers({
    analytics: true,
    marketing: true,
  });

  !isImplicitConsent && setShowCookieBanner(false);
}

function onRefuse(setShowCookieBanner: any) {
  Cookies.set(
    "userPrivacyCookie",
    JSON.stringify({
      analytics: { enabled: false, date: +new Date() },
      marketing: { enabled: false, date: +new Date() },
    }),
    {
      expires: 365,
      path: "/",
      secure: true,
      sameSite: "Strict",
      domain: getCookieDomain(window.location.hostname),
    }
  );
  setShowCookieBanner(false);
}
