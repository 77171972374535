import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CustomImageInterface } from "../molecules/CustomImage";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1TextLarge,
  h1Text,
  textBodyInverse,
  textBodyNeutral,
  pText2XL
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import AboveHeadingChip, {
  AboveHeadingChipInterface,
} from "@/components/molecules/AboveHeadingChip";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  aboveHeading?: string;
  aboveHeadingType?: string;
  aboveHeadingChip?: AboveHeadingChipInterface;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  imageAlign?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  image?: CustomImageInterface;
  contentType: string;
  media?: MediaObjectInterface;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function HomepageHeroBlock({
  includeAboveHeading = false,
  includeButtons = false,
  includeContent = false,
  aboveHeading = "",
  aboveHeadingType = "chip",
  aboveHeadingChip,
  heading = "",
  headingSize = "lg",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "white",
  size = "lg",
  imageAlign = "right",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  const animation = cn(
    "homepage-hero-chip pr-3 rounded-3xl relative h-[45px] overflow-hidden w-fit outline-offset-2 mb-10 mx-auto max-w-full before:absolute before:left-[50%] before:top-[50%] before:translate-x-[-50%] before:translate-y-[-50%] before:w-full before:aspect-[1] before:animate-border-rotate before:content-['']"
  );

  const headingClassNames = cn("pb-2.5", marginBMd, {
    [h1TextLarge]: size === "lg" && headingSize === "lg",
    [h1Text]:
      size === "md" || size === "sm" || (size === "lg" && headingSize === "md"),
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  const contentClassNames = cn(pText2XL, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width="full"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div
        className={`text-left md:text-center lg:grid lg:grid-cols-1 lg:items-center lg:justify-center lg:gap-20`}
      >
        <div className="m-auto max-w-[840px] md:pt-5">
          {includeAboveHeading &&
            aboveHeading &&
            aboveHeadingType === "text" && (
              <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
            )}
          {includeAboveHeading &&
            aboveHeadingChip &&
            aboveHeadingType === "chip" && (
              <div className={animation}>
                <AboveHeadingChip
                  aboveHeadingChip={aboveHeadingChip}
                  className={"relative"}
                  variant={variant}
                  style={{
                    height: "calc(100% - 2* var(--offset))",
                    width: "calc(100% - -4* var(--offset))",
                    inset: "var(--offset)",
                  }}
                />
              </div>
            )}
          <CustomHeading
            heading={heading}
            headingClassNames={headingClassNames}
            headingOptions={headingOptions}
          />
          {includeContent && content && (
            <RichText className={contentClassNames} tag="p">
              {content}
            </RichText>
          )}
          {includeButtons && (
            <CustomButtonGroup align="center" buttons={buttons} />
          )}
        </div>
        <div className="w-full">
          {size !== "sm" && (
            <div
              className={cn("mt-8 w-full lg:mt-0", {
                "order-first": imageAlign === "left",
              })}
            >
              <CustomMedia
                media={media ?? { media: {}, type: "image" }}
                fallbackImage={image}
                className="md:h-full md:w-full"
                videoClassName="w-full aspect-[16/7.4] md:aspect-auto [&>video]:w-full [&>video]:h-full [&>video]:object-cover md:h-full md:[&>video]:object-contain"
              />
            </div>
          )}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupHomepageHero";

HomepageHeroBlock.displayName = BLOCK_NAME;

HomepageHeroBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          aboveHeadingAnimation
          aboveHeadingChip
          aboveHeadingType
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          ga4SectionId
          heading
          headingOptions
          headingSize
          image
          imageAlign
          includeAboveHeading
          includeButtons
          includeContent
          media
          metadata
          size
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
