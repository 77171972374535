export const BLOG_SIGN_UP_TITLE = {
  "en": "Stay in touch with Dashlane",
  "fr": "Restez en contact avec Dashlane",
  "de": "Mit Dashlane in Kontakt bleiben",
  "es": "Manténgase en contacto con Dashlane",
  "pt-br": "Stay in touch with Dashlane",
  "it": "Stay in touch with Dashlane",
  "ja": "Stay in touch with Dashlane",
  "ko": "Stay in touch with Dashlane",
  "nl": "Stay in touch with Dashlane",
  "pt-pt": "Stay in touch with Dashlane",
  "sv": "Stay in touch with Dashlane",
  "zh": "Stay in touch with Dashlane"
}