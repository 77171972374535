export const RESOURCE_ARCHIVE_UPCOMING_HEADING = {
  "en": "Upcoming events",
  "fr": "Upcoming events",
  "de": "Upcoming events",
  "es": "Upcoming events",
  "pt-br": "Upcoming events",
  "nl": "Upcoming events",
  "it": "Upcoming events",
  "ja": "Upcoming events",
  "ko": "Upcoming events",
  "pt-pt": "Upcoming events",
  "sv": "Upcoming events",
  "zh": "Upcoming events"
}