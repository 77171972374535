import cn from "classnames";
import { LanguageSwitcher, Navigation } from "@/components/molecules";
import type { MenuItems, MenuItem } from "@/types";
import { useTranslate } from "@/hooks/useTranslate";
import { COPYRIGHT_DISCLAIMER } from "@/i18n/translations/COPYRIGHT_DISCLAIMER";
import { COPYRIGHT_TITLE } from "@/i18n/translations/COPYRIGHT_TITLE";
import { Locale, Translated } from "@/types";
import React from "react";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";
import Link from "next/link";
import { getMenuItemIds } from "@/utilities/getMenuItemIds";

interface Props {
  title: string;
  menuItems: MenuItems;
  availableLocales?: Translated[];
  locale?: Locale;
  landing?: boolean;
}

export default function Footer({
  menuItems,
  availableLocales,
  locale,
  landing = false,
}: Props) {
  const { translate } = useTranslate();
  const year = new Date().getFullYear();

  //Get the last column - this will be the Terms, Privacy Policy, etc. menu
  const newMenuItems = flatListToHierarchical(menuItems);
  const lastMenuItem = newMenuItems.pop() as MenuItem;

  //Get the menu item ids of the last menu item(s) so that we can remove them
  const lastMenuIds = getMenuItemIds(lastMenuItem);

  //Remove the menu items that are for the Terms, Privacy Policy, etc. menu
  menuItems = menuItems.filter((each) => !lastMenuIds.includes(each.id));

  function DynamicLink({ item, index }: { item: MenuItem; index: number }) {
    const { path, target, label } = item;
    const dallasReactPaths = ["privacy", "whitepaper"];
    const isDallasReactPage = dallasReactPaths.find((itemPath) =>
      path.includes(itemPath)
    );

    // Removes trailing slash errors in the console
    const urlWithoutTrailingSlash = path.endsWith("/")
      ? path.slice(0, -1)
      : path;

    const linkClassName = cn(
      "border border-b-0 border-r-0 border-t-0 border-grey-3 text-center text-xs font-medium uppercase leading-[14px] text-grey-3 hover:no-underline lg:text-sm",
      {
        "border-l-0 pe-2": index === 0,
        "px-2":
          index !== 0 && index !== (lastMenuItem.children ?? []).length - 1,
        "ps-2": index === (lastMenuItem.children ?? []).length - 1,
      }
    );

    const props = {
      className: linkClassName,
      href: urlWithoutTrailingSlash,
      target: target ?? "_self",
    };

    return isDallasReactPage ? (
      <a {...props}>{label}</a>
    ) : (
      <Link {...props}>{label}</Link>
    );
  }

  return (
    <footer className="bg-dashgreen">
      <div className="mx-auto flex max-w-7xl flex-col px-4 py-8 leading-6 md:px-8 lg:gap-10 lg:py-20">
        {!landing && <Navigation menuItems={menuItems} layout="Footer" />}
        <div className="flex flex-col justify-between gap-x-16 lg:mt-0 lg:flex-row lg:gap-y-8">
          <div className="flex justify-center lg:hidden">
            <LanguageSwitcher
              availableLocales={availableLocales}
              locale={locale}
              location="footer"
            />
          </div>
          <div className="px-10 lg:max-w-sm lg:px-0">
            <p className="text-center text-white lg:text-left lg:text-base">
              {`© ${year} Dashlane Inc. ${translate(COPYRIGHT_TITLE)}`}
            </p>
            <p className="mt-4 text-center text-sm text-grey-3 lg:mt-2 lg:text-left">
              {translate(COPYRIGHT_DISCLAIMER)}
            </p>
          </div>
          <div className="px-10 text-center lg:px-0 lg:text-left">
            <div className="hidden lg:block">
              <LanguageSwitcher
                availableLocales={availableLocales}
                locale={locale}
                location="footer"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-end lg:flex-row">
          <ul className="mt-4 px-10 text-center lg:mt-0 lg:px-0 lg:text-left">
            {lastMenuItem?.children?.map((each: MenuItem, index: number) => {
              const { id } = each;

              return (
                <li className="inline" key={id}>
                  <DynamicLink item={each} index={index} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
}
