export const PRIVACY_CONSENT_TITLE_REDESIGN = {
  "en": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "fr": "",
  "de": "",
  "es": "",
  "pt-br": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "it": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "ja": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "ko": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "nl": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "pt-pt": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "sv": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "zh": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:"
}