export const BLOG_PUBLISHED = {
  "en": "Published:",
  "fr": "Date de publication :",
  "de": "Veröffentlicht:",
  "es": "Publicado:",
  "pt-br": "Published:",
  "it": "Published:",
  "ja": "Published:",
  "ko": "Published:",
  "nl": "Published:",
  "pt-pt": "Published:",
  "sv": "Published:",
  "zh": "Published:"
}