export const RESOURCE_ARCHIVE_FEATURED_HEADING = {
  "en": "Featured",
  "fr": "Featured",
  "de": "Featured",
  "es": "Featured",
  "pt-br": "Featured",
  "nl": "Featured",
  "it": "Featured",
  "ja": "Featured",
  "ko": "Featured",
  "pt-pt": "Featured",
  "sv": "Featured",
  "zh": "Featured"
}