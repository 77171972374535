export const BLOG_UPDATED = {
  "en": "Updated:",
  "fr": "Mis à jour :",
  "de": "Aktualisiert:",
  "es": "Actualizado:",
  "pt-br": "Updated:",
  "it": "Updated:",
  "ja": "Updated:",
  "ko": "Updated:",
  "nl": "Updated:",
  "pt-pt": "Updated:",
  "sv": "Updated:",
  "zh": "Updated:"
}