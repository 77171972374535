export const RESOURCE_SINGLE_RELATED_HEADING = {
  "en": "Related Resources",
  "fr": "Related Resources",
  "de": "Related Resources",
  "es": "Related Resources",
  "pt-br": "Related Resources",
  "nl": "Related Resources",
  "it": "Related Resources",
  "ja": "Related Resources",
  "ko": "Related Resources",
  "pt-pt": "Related Resources",
  "sv": "Related Resources",
  "zh": "Related Resources"
}