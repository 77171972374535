import Head from "next/head";
import sanitizeHtml from "sanitize-html";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { Locale, Translated } from "@/types";
import Hreflang from "@/components/molecules/Hreflang";
import Canonical from "./Canonical";

interface Props {
  fullHead?: string;
  title?: string;
  description?: string;
  availableLocales?: Translated[];
  locale?: Locale;
  uri?: string;
  isFrontPage?: boolean;
}

function getPathAndCanonical(
  isFrontPage?: boolean,
  locale?: Locale,
  uri?: string
) {
  const currentUrl = process.env["NEXT_PUBLIC_SITE_URL"];

  const parsedPath = `${uri?.startsWith("/") ? "" : "/"}${uri}`;
  const noTrailingSlashPath = parsedPath === "/" ? "" : parsedPath;

  const canonical =
    locale === "en"
      ? isFrontPage
        ? currentUrl
        : `${currentUrl}${parsedPath}`
      : `${currentUrl}${
          isFrontPage
            ? noTrailingSlashPath.replace(/\/$/, "")
            : noTrailingSlashPath.replace(/\/$/, "")
        }`;

  return {
    canonical,
  };
}

export default function SEO({
  fullHead,
  title,
  description,
  availableLocales,
  locale,
  uri,
  isFrontPage,
}: Props) {
  const [shouldNotIndex, setShouldNotIndex] = useState(false);

  if (shouldNotIndex) {
    fullHead = fullHead?.replace(
      /<meta[^>]*name="robots"[^>]*index[^>]*>/gi,
      '<meta name="robots" content="noindex, follow" />'
    );
  }

  // Since this is a client-side effect, does this actually work for no-indexing? Or should we use .env variable?
  useEffect(() => {
    const isDashlaneDomain = /^https:\/\/(www|tests)\.dashlane\.com/gi.test(
      window.location.href
    );

    const isTagURL = /\/blog\/tag\//gi.test(window.location.href);

    if (!isDashlaneDomain || isTagURL) {
      setShouldNotIndex(true);
    }
  }, []);

  const hreflang = (
    <Hreflang
      availableLocales={availableLocales}
      locale={locale}
      uri={uri}
      isFrontPage={isFrontPage}
    />
  );

  const { canonical = "" } = getPathAndCanonical(isFrontPage, locale, uri);
  const canonicalComponent = <Canonical canonical={canonical} />;

  const smartAppBanner = (
    <meta
      name="apple-itunes-app"
      content={`app-id=517914548, app-argument=${canonical}`}
    />
  );

  if (fullHead) {
    const sanitizedHead = parse(
      sanitizeHtml(fullHead, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "meta",
          "title",
        ]),
        allowedAttributes: false,
      })
    );

    return (
      <Head>
        {sanitizedHead}
        {hreflang}
        {canonicalComponent}
        {smartAppBanner}
      </Head>
    );
  } else {
    return (
      <Head>
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <title>{title}</title>
        {hreflang}
        {canonicalComponent}
        {smartAppBanner}
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="twitter:description" content={description} />
        {shouldNotIndex && <meta name="robots" content="noindex, follow" />}
      </Head>
    );
  }
}
