export const PRIVACY_CONSENT_ACCEPT_REDESIGN = {
  "en": "Accept cookies",
  "fr": "",
  "de": "",
  "es": "",
  "pt-br": "Accept cookies",
  "it": "Accept cookies",
  "ja": "Accept cookies",
  "ko": "Accept cookies",
  "nl": "Accept cookies",
  "pt-pt": "Accept cookies",
  "sv": "Accept cookies",
  "zh": "Accept cookies"
}